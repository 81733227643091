package header

import react.*
import react.dom.*
import kotlinext.js.*
import kotlinx.html.style

fun RBuilder.header() {
    div("appheader") {
      h1 {+"new generations"}
      h2 {+"qazx7412,limit7412,kairox,回路"}
      p {+"勢いでドメイン取っちゃったんだけどなにしよ…"}
      p {+"なんもアイディア思いつかない"}
    }
}

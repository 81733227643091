package catch

import react.*
import react.dom.*
import kotlinext.js.*
import kotlinx.html.style

fun RBuilder.catch() {
    div("indexcatch") {}
}
